import { useRecoilState, useRecoilValue } from 'recoil'
import streamingListToBottomAtom from '../../recoil/streamingListToBottom'
import targetedPodsAtom from '../../recoil/targetedPods'
import React, { useEffect, useState } from 'react'
import TrafficViewerStyles from './TrafficViewer.module.sass'
import useStreamingWebSocket from '../../hooks/useStreamingWebSocket'
import { V1Pod } from '@kubernetes/client-node'

const useStreamingList = () => {
  const { readyState } = useStreamingWebSocket()

  const [toBottom, setToBottom] = useRecoilState<boolean>(streamingListToBottomAtom)
  const targets = useRecoilValue<V1Pod[]>(targetedPodsAtom)

  const [connectionTitle, setConnectionTitle] = useState('streaming paused')
  const [connectionIndicator, setConnectionIndicator] = useState(null)

  useEffect(() => {
    switch (readyState) {
    case WebSocket.OPEN:
      setConnectionTitle(
        targets.length === 0 ? 'offline mode' : 'streaming live traffic'
      )
      setConnectionIndicator(
        <div
          className={`${TrafficViewerStyles.indicatorContainer} ${TrafficViewerStyles.greenIndicatorContainer}`}
        >
          <div
            className={`${TrafficViewerStyles.indicator} ${TrafficViewerStyles.greenIndicator}`}
          />
        </div>
      )
      break
    default:
      setConnectionTitle(targets.length === 0 ? 'paused' : 'streaming paused')
      setConnectionIndicator(
        <div
          className={`${TrafficViewerStyles.indicatorContainer} ${TrafficViewerStyles.redIndicatorContainer}`}
        >
          <div
            className={`${TrafficViewerStyles.indicator} ${TrafficViewerStyles.redIndicator}`}
          />
        </div>
      )
    }
  }, [readyState, targets.length])

  return {
    toBottom,
    setToBottom,
    connectionTitle,
    connectionIndicator,
  }
}

export default useStreamingList
