import { HubScriptLogsWsUrl } from '../consts'
import { useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import scriptLogsAtom from '../recoil/scriptLogs'
import trafficPlaybackAtom, { TRAFFIC_PLAYBACK_OK } from '../recoil/trafficPlayback/atom'
import { appStopTrafficCapturingDisabled } from '../types/global'
import packetCapturingStoppedAtom from '../recoil/packetCapturingStopped'
import { useAuth } from '../components/UI/Auth/Auth'
import useScriptLogWebSocket from './useScriptLogWebSocket'

export interface ScriptLogs {
  scriptIndex: number
  scriptTitle: string
  logs: string
}

const useScriptLogTracker = () => {
  const { shouldAuthenticate } = useAuth()

  const trafficPlayback = useRecoilValue(trafficPlaybackAtom)
  const capturingStopped = useRecoilValue(packetCapturingStoppedAtom)
  const setScriptLogs = useSetRecoilState(scriptLogsAtom)

  const { open, close, reopen } = useScriptLogWebSocket({
    wsUrl: HubScriptLogsWsUrl,
    withAuth: false,
    withIdleTimer: false,
    onMessage: (e) => {
      if (!e?.data) {
        return
      }

      const logMessage = e.data
      const logPrefix = logMessage.split(' ')[0].replace(/[[\]]/g, '').split('-')
      const scriptIndex: number = parseInt(logPrefix[logPrefix.length - 1])

      setScriptLogs(prevLogs => {
        const updatedLogs = {...prevLogs}
        const scriptLog: ScriptLogs = {...updatedLogs[scriptIndex]}

        if (Object.keys(scriptLog).length === 0) {
          updatedLogs[scriptIndex] = {
            scriptIndex,
            logs: logMessage
          } as ScriptLogs
        } else {
          scriptLog.logs = `${scriptLog.logs}\n${logMessage}`
          updatedLogs[scriptIndex] = scriptLog
        }

        return updatedLogs
      })
    },
    onError: (e) => {
      console.error('Script logs websocket error', e)
      console.log('Trying to reopen script logs websocket...')
      reopen()
    }
  })

  useEffect(() => {
    if (trafficPlayback !== TRAFFIC_PLAYBACK_OK || shouldAuthenticate) {
      close()
      return
    }

    if (
      !appStopTrafficCapturingDisabled() &&
      (capturingStopped === null || capturingStopped)
    ) {
      close()
      return
    }

    open()

    return () => close()
  }, [trafficPlayback, shouldAuthenticate, capturingStopped])
}

export default useScriptLogTracker
