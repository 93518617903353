import React, { useRef } from 'react'
import { VirtualizedListHandle } from '../../UI/VirtualizedList/VirtualizedList'

import queryBuildAtom from "../../../recoil/queryBuild";
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { Filters } from '../../Filters/Filters'
import { EntriesList } from '../../EntriesList/EntriesList'
import { EntryDetailed } from '../../EntryDetailed/EntryDetailed'

import variables from '../../../variables.module.scss';
import styles from "./ApiCallsView.module.sass"
import EntriesListStyles from '../../EntriesList/EntriesList.module.sass';
import { Box, ButtonBase, Drawer, Slide, Tooltip, Typography } from '@mui/material';
import { FullscreenViewButton } from '../../UI/FullscreenView/FullscreenViewButton';
import entryDetailsOpenAtom from '../../../recoil/entryDetailsOpen/atom';
import { CollapsePanelIcon } from '../../UI/Icons/CollapsePanelIcon';
import entryDetailsViewModeAtom from '../../../recoil/entryDetailsViewMode';

interface ApiCallsViewProps {
  scrollableRef: React.MutableRefObject<VirtualizedListHandle>;
}

interface PresentationProps {
  children: JSX.Element
  mode: 'drawer' | 'slide'
  open: boolean
  handleClose: () => void
}

const Presentation: React.FC<PresentationProps> = ({ children, mode, open, handleClose }) => {
  if (mode === 'drawer') {
    return (
      <Drawer
        anchor='bottom'
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { height: '76vh' }}}
      >
        {children}
      </Drawer>
    )
  }

  if (mode === 'slide') {
    return (
      <Slide direction={"left"} in={open} mountOnEnter unmountOnExit>
        {children}
      </Slide>
    )
  }

  return open && children
}

export const ApiCallsView: React.FC<ApiCallsViewProps> = ({ scrollableRef }) => {
  const setQueryBuild = useSetRecoilState(queryBuildAtom);

  const [entryDetailsOpen, setEntryDetailsOpen] = useRecoilState(entryDetailsOpenAtom)

  const handleCloseEntryDetails = () => setEntryDetailsOpen(false)

  const trafficPageContainerRef = useRef(null)

  const viewMode = useRecoilValue(entryDetailsViewModeAtom)

  return (
    <div className={styles.TrafficPageContainer} ref={trafficPageContainerRef}>
      <div className={styles.TrafficPageListContainer}>
        <Box position='absolute' left={16} right={24} width='calc(100% - 28px)' zIndex={1} bgcolor={variables.dataBackgroundColor}>
          <Filters
            onQueryChange={(q) => {
              setQueryBuild(q?.trim())
            }}
          />
        </Box>
        <div className={EntriesListStyles.container}>
          <EntriesList scrollableRef={scrollableRef} />
        </div>
      </div>
      <Presentation mode={viewMode} open={entryDetailsOpen} handleClose={handleCloseEntryDetails}>
        <Box flex='0 0 50%' width={viewMode === 'slide' ? '45vw' : 'unset'} marginTop='9px' paddingRight='16px'>
          <Box display='flex' alignItems='center' justifyContent='space-between' marginBottom='8px'
            paddingLeft={viewMode === 'drawer' ? '24px' : 'unset'} sx={viewMode === 'drawer' ? {
              position: 'sticky',
              background: 'white',
              zIndex: 1000,
              paddingTop: 0,
              paddingBottom: 0,
              height: '40px',
              top: 0,
            } : null}>
            <Box display='flex' alignItems='center'>
              <Typography variant='body1' fontFamily='Roboto' fontSize='14px' color={variables.secondaryFontColor}>API Calls Details</Typography>
            </Box>
            <Box display='flex' alignItems='center' gap='10px'>
              <Tooltip
                arrow
                placement='top'
                title='Collapse API calls details'
              >
                <ButtonBase
                  onClick={handleCloseEntryDetails}
                  sx={{ borderRadius: '6px' }}
                >
                  <Box sx={{ height: '30px', transform: viewMode === 'drawer' ? 'rotate(90deg)' : null }}>
                    <CollapsePanelIcon />
                  </Box>
                </ButtonBase>
              </Tooltip>
              <FullscreenViewButton />
            </Box>
          </Box>
          <div className={styles.EntryDetailedContainer} id='rightSideContainer'>
            <EntryDetailed />
          </div>
        </Box>
      </Presentation>
    </div>
  )
}
