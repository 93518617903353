import { atom } from "recoil";
import { EnabledDissectors } from '../../types/trafficSettings'

const enabledDissectorsAtom = atom<EnabledDissectors>({
  key: "enabledDissectorsAtom",
  default: {
    amqp: false,
    dns: false,
    http: false,
    icmp: false,
    kafka: false,
    redis: false,
    sctp: false,
    syscall: false,
    ws: false,
    tcp: false,     
    udp: false,
    tls: false,
  },
});

export default enabledDissectorsAtom;
