import React, { useEffect, useState } from "react";
import EntryViewer from "./EntryViewer/EntryViewer";
import Protocol, { ProtocolInterface } from "../UI/Protocol/Protocol"
import Queryable from "../UI/Queryable/Queryable";
import { useRecoilState, useRecoilValue } from "recoil";
import focusedItemAtom from "../../recoil/focusedItem";
import focusedRecordAtom from "../../recoil/focusedRecord";
import queryAtom from "../../recoil/query";
import useWindowDimensions, { useRequestTextByWidth } from "../../hooks/WindowDimensionsHook";
import entryDataAtom from "../../recoil/entryData";
import { HubBaseUrl } from "../../consts";
import { Entry } from "../EntryListItem/Entry";
import { TcpStream } from "./TcpStream/TcpStream";
import { Box, Button, Link, Typography } from '@mui/material'
import MoreTimeRoundedIcon from '@mui/icons-material/MoreTimeRounded';

import variables from '../../variables.module.scss'
import styles from './EntryDetailed.module.sass'
import { TrafficEntry } from "../TrafficEntry/TrafficEntry";
import { DetailsNotFoundPanel } from './DetailsNotFoundPanel/DetailsNotFoundPanel'
import focusedEntryAtom from '../../recoil/focusedEntry'
import { LoadingBadge } from './LoadingBadge'
import { EntryDetailedSkeleton } from './EntryDetailedSkeleton'
import packetCapturingStoppedAtom from '../../recoil/packetCapturingStopped'

interface DataModel {
  request: unknown;
  response: unknown;
  data: unknown;
  requestSize: number;
  responseSize: number;
  size: number;
}

interface EntryTitleProps {
  protocol: ProtocolInterface;
  data: DataModel;
  elapsedTime: number;
  error: boolean;
}

export const formatSize = (n: number): string => n > 1000 ? `${Math.round(n / 1000)}kB` : `${n}B`;
const minSizeDisplayRequestSize = 880;
const EntryTitle: React.FC<EntryTitleProps> = ({ protocol, data, elapsedTime, error }) => {
  const request = data.request;
  const response = data.response;
  const dataField = data.data;

  const { width } = useWindowDimensions();
  const { requestText, responseText, elapsedTimeText } = useRequestTextByWidth(width)

  return <div className={styles.EntryDetailed}>
    <Protocol protocol={protocol} horizontal={true} error={error} />
    {(width > minSizeDisplayRequestSize) && <div style={{ display: "flex" }}>
      {request && <Queryable
        query={`requestSize == ${data.requestSize}`}
        style={{ margin: "0 18px" }}
        tooltipStyle={{ textWrap: 'nowrap', top: '5px' }}
        iconStyle={{ marginTop: "32px", position: 'absolute' }}
        displayIconOnMouseOver={true}
      >
        <div
          style={{ opacity: 0.5 }}
          id="entryDetailedTitleRequestSize"
        >
          {`${requestText}${formatSize(data.requestSize)}`}
        </div>
      </Queryable>}
      {response && <Queryable
        query={`responseSize == ${data.responseSize}`}
        style={{ margin: "0 18px" }}
        tooltipStyle={{ textWrap: 'nowrap', top: '5px' }}
        iconStyle={{ marginTop: "32px", position: 'absolute' }}
        displayIconOnMouseOver={true}
      >
        <div
          style={{ opacity: 0.5 }}
          id="entryDetailedTitleResponseSize"
        >
          {`${responseText}${formatSize(data.responseSize)}`}
        </div>
      </Queryable>}
      {dataField && <Queryable
        query={`sizes == ${data.size}`}
        style={{ margin: "0 18px" }}
        tooltipStyle={{ textWrap: 'nowrap', top: '5px' }}
        iconStyle={{ marginTop: "32px", position: 'absolute' }}
        displayIconOnMouseOver={true}
      >
        <div
          style={{ opacity: 0.5 }}
          id="entryDetailedTitleSize"
        >
          {`Size: ${formatSize(data.size)}`}
        </div>
      </Queryable>}
      {response && <Queryable
        query={`elapsedTime >= ${elapsedTime}`}
        style={{ margin: "0 0 0 18px" }}
        tooltipStyle={{ textWrap: 'nowrap', top: '5px', right: '5px' }}
        iconStyle={{ marginTop: "32px", position: 'absolute', right: '22px' }}
        displayIconOnMouseOver={true}
      >
        <div
          style={{ opacity: 0.5 }}
          id="entryDetailedTitleElapsedTime"
        >
          {`${elapsedTimeText}${Math.round(elapsedTime)} μs`}
        </div>
      </Queryable>}
    </div>}
  </div>;
};

interface EntrySummaryProps {
  entry: Entry;
}

const EntrySummary: React.FC<EntrySummaryProps> = ({ entry }) => {
  return <TrafficEntry
    key={entry.id}
    entry={entry}
    style={{}}
    headingMode={true}
  />;
};

interface DetailsNotFound {
  state: boolean
  reason?: string
  solution?: string | JSX.Element
}

export const EntryDetailed: React.FC = () => {
  const focusedEntry = useRecoilValue(focusedEntryAtom)
  const focusedItem = useRecoilValue(focusedItemAtom);
  const focusedRecord = useRecoilValue(focusedRecordAtom);
  const query = useRecoilValue(queryAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [showLoadingBadge, setShowLoadingBadge] = useState(false);
  const [detailsNotFound, setDetailsNotFound] = useState<DetailsNotFound>({ state: false })
  const [entryData, setEntryData] = useRecoilState(entryDataAtom)
  const capturingStopped = useRecoilValue(packetCapturingStoppedAtom)

  const fetchItem = () => {
    let statusCode: number;

    if (!focusedItem) return;
    fetch(
      `${HubBaseUrl}/item/${focusedItem}?r=${encodeURIComponent(focusedRecord)}&q=${encodeURIComponent(query)}`,
      {
        method: 'GET',
        headers: {
          'X-Kubeshark-Capture': 'ignore',
        },
      },
    )
      .then(response => {
        statusCode = response.status
        return response.ok ? response : response.text().then(err => Promise.reject(err))
      })
      .then(response => response.json())
      .then(data => {
        setEntryData(data);
        setDetailsNotFound({ state: false, reason: '', solution: '' })
      })
      .catch(err => {
        if (err.length > 0) {
          console.error(err);
          if (statusCode === 404) {
            setDetailsNotFound({
              state: true,
              reason: err.toString(),
              solution: <>
                <Typography variant='body1' color={variables.fontColor} fontFamily={variables.textFontFamily}>
                  TTL can be increased. Read more about&nbsp;
                </Typography>
                <Link href="https://docs.kubeshark.co/en/traffic_retention" target="_blank">
                  <Button
                    color='success'
                    variant='contained'
                    size='small'
                    className='themeButton success'
                    startIcon={
                      <MoreTimeRoundedIcon htmlColor='#ffffff' sx={{ marginRight: '5px' }} />
                    }
                  >
                    <Typography
                      variant='body1'
                      fontSize={14}
                      fontFamily={variables.textFontFamily}
                      fontWeight={600}
                      color='#ffffff'
                    >
                      Data Time To Live (TTL)
                    </Typography>
                  </Button>
                </Link>
              </>
            })
          }
        }
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }

  useEffect(() => {
    if (!focusedItem) {
      setIsLoading(false)
      setEntryData(null)
      return
    }
    if (query.match(/record\(.+\)/g) && !focusedRecord) return;

    setIsLoading(true)
    setEntryData(null)
    fetchItem()
  }, [focusedItem, query, focusedRecord]);

  useEffect(() => {
    let timeout: NodeJS.Timeout = null

    if (isLoading) {
      timeout = setTimeout(() => setShowLoadingBadge(true), 1000)
    } else {
      setShowLoadingBadge(false)
    }

    return () => clearTimeout(timeout)
  }, [isLoading])

  if (capturingStopped) {
    return null
  }

  if (isLoading) {
    return (
      <Box position='relative'>
        {showLoadingBadge && <LoadingBadge />}
        <EntryDetailedSkeleton />
      </Box>
    )
  }

  return (
    <>
      <DetailsNotFoundPanel
        entry={focusedEntry}
        state={detailsNotFound.state}
        reason={detailsNotFound.reason}
        solution={detailsNotFound.solution}
      />
      {focusedItem && entryData && (
        <React.Fragment>
          <EntryTitle
            protocol={entryData.protocol}
            data={entryData.data}
            elapsedTime={entryData.data.elapsedTime}
            error={entryData.data.error !== null}
          />
          <EntrySummary entry={entryData.base} />
          <TcpStream
            index={entryData.data.index}
            stream={entryData.data.stream}
            worker={entryData.data.worker}
            node={entryData.data.node}
            color={entryData.protocol.backgroundColor}
            layer4={entryData.protocol.layer4.toUpperCase()}
          />
          <EntryViewer
            representation={entryData.representation}
            data={entryData.data}
            color={entryData.protocol.backgroundColor}
          />
        </React.Fragment>
      )}
    </>
  )
};
