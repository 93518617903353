import React, { useEffect, useState } from 'react'

import './ConsoleView.sass'
import styles from './ConsoleView.module.sass'
import { TrafficStatsFooter } from '../TrafficStatsFooter/TrafficStatsFooter'
import { Box, Fab } from '@mui/material'
import { LazyLog } from 'react-lazylog'

import variables from '../../../variables.module.scss'
import { ConsoleTab } from './ConsoleTab/ConsoleTab'
import { FullscreenViewButton } from '../../UI/FullscreenView/FullscreenViewButton'
import CodeRoundedIcon from '@mui/icons-material/CodeRounded'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import scriptLogsAtom from '../../../recoil/scriptLogs'
import { ScriptLogs } from '../../../hooks/useScriptLogTracker'
import scriptsAtom from '../../../recoil/scripts'
import { getScripts } from '../ScriptingView/api/getScripts'
import { Script, ScriptMap } from '../../modals/ScriptingModal/ScriptingTypes'
import { ConsoleBanner } from './ConsoleBanner/ConsoleBanner'
import { trafficViewTabs } from '../../../recoil/trafficViewTab/atom'
import trafficViewTabAtom from '../../../recoil/trafficViewTab'
import scriptIndexOnLoadAtom from '../../../recoil/scriptIndexOnLoad'
import scriptLogIndexOnLoadAtom from '../../../recoil/scriptLogIndexOnLoad'

const MAX_SHOWN_TABS = 8

export const ConsoleView = () => {
  const [scripts, setScripts] = useRecoilState(scriptsAtom)
  const scriptLogs = useRecoilValue(scriptLogsAtom)
  const [scriptLogIndexOnLoad, setScriptLogIndexOnLoad] = useRecoilState(scriptLogIndexOnLoadAtom)

  const setTrafficViewTab = useSetRecoilState(trafficViewTabAtom)
  const setScriptIndexOnLoad = useSetRecoilState(scriptIndexOnLoadAtom)

  const [scriptsLoaded, setScriptsLoaded] = useState(false)
  const [shownScriptLogs, setShownScriptLogs] = useState<ScriptMap>({})
  const [dropdownScriptLogs, setDropdownScriptLogs] = useState<ScriptMap>({})
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [consoleLogs, setConsoleLogs] = useState('Waiting for logs...')
  const [compactView, setCompactView] = useState(false)

  const darkModeEnabled = true

  // Set initial console tab
  useEffect(() => {
    if (selectedIndex !== -1 || !scriptLogs || Object.keys(scriptLogs).length === 0) {
      return
    }

    const scriptLog = Object.values(scriptLogs)[0] as ScriptLogs
    setSelectedIndex(scriptLog.scriptIndex)
  }, [scriptLogs])

  // Continuously set console logs, if console tab is chosen
  useEffect(() => {
    if (selectedIndex === -1 || !scriptLogs || Object.keys(scriptLogs).length === 0) {
      return
    }

    const scriptLog = scriptLogs[selectedIndex] as ScriptLogs
    setConsoleLogs(scriptLog?.logs ?? 'Waiting for logs...')
  }, [scriptLogs, selectedIndex])

  // Set console logs once tab changed
  useEffect(() => {
    const scriptLog: ScriptLogs = scriptLogs[selectedIndex]
    setConsoleLogs(scriptLog?.logs ?? 'Waiting for logs...')
  }, [selectedIndex])

  // Open a specific console tab once Console view is loaded.
  // This hook executes if user clicks "Script Logs" from Script view.
  useEffect(() => {
    if (scriptLogIndexOnLoad === null || !scriptsLoaded) {
      return
    }

    setSelectedIndex(scriptLogIndexOnLoad)

    const scriptLog: ScriptLogs = scriptLogs[scriptLogIndexOnLoad]

    setConsoleLogs(scriptLog ? scriptLog.logs : 'Waiting for logs...')
    setScriptLogIndexOnLoad(null)
  }, [scriptLogIndexOnLoad, scriptsLoaded, scriptLogs])

  // Get all scripts once Console view is loaded
  useEffect(() => {
    getScripts().then(scripts => {
      setScripts(scripts)
    })

    return () => setScriptLogIndexOnLoad(null)
  }, [])

  // Distribute scripts into main tabs & dropdown tabs
  useEffect(() => {
    const shownActiveScripts: Script[] = []
    const dropdownActiveScripts: Script[] = []

    if (!scripts) {
      return
    }

    const scriptEntries = Object.entries(scripts)

    if (scriptEntries.length > MAX_SHOWN_TABS) {
      const shownScripts = scriptEntries.slice(0, MAX_SHOWN_TABS)
      Object.entries(shownScripts).map(scriptVal => {
        const script = {...scriptVal[1][1]}
        script.index = parseInt(scriptVal[1][0])

        if (script.active) {
          shownActiveScripts.push(script)
        }
      })

      const dropdownScripts = scriptEntries.slice(MAX_SHOWN_TABS)
      Object.entries(dropdownScripts).map(scriptVal => {
        const script = {...scriptVal[1][1]}
        script.index = parseInt(scriptVal[1][0])

        if (script.active) {
          dropdownActiveScripts.push(script)
        }
      })

      setShownScriptLogs(shownActiveScripts)
      setDropdownScriptLogs(dropdownActiveScripts)
    } else {
      Object.entries(scriptEntries).map(scriptVal => {
        const script = {...scriptVal[1][1]}
        script.index = parseInt(scriptVal[1][0])

        if (script.active) {
          shownActiveScripts.push(script)
        }
      })

      setShownScriptLogs(shownActiveScripts)
    }

    setTimeout(() => setScriptsLoaded(true), 500)
  }, [scripts])

  return (
    <div className={styles.ConsoleViewContainer}>
      <Box
        className={styles.FillAllSpaceContainer}
        padding='0 16px'
        paddingTop='10px'
        position='relative'
      >
        {!scriptsLoaded && (
          <ConsoleBanner
            title='Looking for active script logs...'
            icon={
              <SearchRoundedIcon
                htmlColor={variables.slateColor}
                sx={{
                  fontSize: '96px'
                }}
              />
            }
          />
        )}
        {scriptsLoaded && Object.entries(shownScriptLogs).length === 0 && (
          <ConsoleBanner
            title='Create & activate a script to start receiving logs'
            icon={
              <CodeRoundedIcon
                htmlColor={variables.slateColor}
                sx={{
                  fontSize: '96px'
                }}
              />
            }
          />
        )}
        {scriptsLoaded && Object.entries(shownScriptLogs).length > 0 && (
          <>
            <Box
              boxSizing='border-box'
              width='100%'
              display='flex'
              alignItems='center'
              bgcolor={variables.githubEditorBackgroundColorLight}
            >
              <Box className={styles.ConsoleTabsContainer}>
                {Object.values(dropdownScriptLogs).length > 0 && (
                  <ConsoleTab
                    selected={
                      Object.values(dropdownScriptLogs)
                        .findIndex(script => selectedIndex === script.index) !== -1
                    }
                    asDropdown
                    dropdownTabs={Object.values(dropdownScriptLogs).map(script => {
                      return {
                        title: script.title,
                        index: script.index,
                        selected: selectedIndex === script.index,
                        onSelection: (index) => {
                          setSelectedIndex(index)
                        }
                      }
                    })}
                  />
                )}
                {Object.values(shownScriptLogs).map((script) => {
                  return (
                    <ConsoleTab
                      key={script.index}
                      index={script.index}
                      selected={selectedIndex === script.index}
                      onSelection={(index) => setSelectedIndex(index)}
                      title={script.title}
                    />
                  )
                })}
              </Box>
              <Box
                height='40px'
                maxHeight='40px'
                boxSizing='border-box'
                padding='4px 10px'
                display='flex'
                alignItems='center'
                gap='5px'
                borderBottom={`1px solid ${variables.slateColor}`}
              >
                <FullscreenViewButton
                  iconColor={variables.headerBackgroundColor}
                  bgColor='transparent'
                  tooltipPlacement='top-start'
                  onChange={(fullscreen) => setCompactView(fullscreen)}
                  onInit={(fullscreen) => setCompactView(fullscreen)}
                />
              </Box>
            </Box>
            <Box className={styles.FillAllSpaceContainer}>
              <Box
                className={styles.LogContainer}
                sx={{
                  height: `calc(100vh - ${compactView ? 70 : 170}px)`,
                }}
              >
                <LazyLog
                  stream
                  text={consoleLogs}
                  extraLines={1}
                  enableSearch
                  follow
                  selectableLines
                  height='auto'
                  width='auto'
                  style={{
                    backgroundColor: variables.githubEditorBackgroundColor,
                  }}
                />
              </Box>
              <Box
                display='flex'
                alignItems='center'
                gap='15px'
                sx={{
                  position: 'absolute',
                  bottom: '20px',
                  right: '20px'
                }}
              >
                <Fab
                  variant='extended'
                  color='default'
                  onClick={() => {
                    setTrafficViewTab(trafficViewTabs.SCRIPTING)
                    setScriptIndexOnLoad(selectedIndex)
                  }}
                  sx={{
                    backgroundColor: darkModeEnabled ? variables.githubEditorBackgroundColorLight : variables.slateColor,
                    color: darkModeEnabled ? variables.lighterGrayColor : variables.mainBackgroundColor,
                    '&:hover': {
                      backgroundColor: darkModeEnabled ? variables.githubEditorBackgroundColorLight : variables.slateColor
                    }
                  }}
                >
                  <CodeRoundedIcon htmlColor={darkModeEnabled ? variables.lighterGrayColor : variables.mainBackgroundColor} sx={{ mr: '10px' }} />
                  Go to Script
                </Fab>
              </Box>
            </Box>
          </>
        )}
        <TrafficStatsFooter hideDivider />
      </Box>
    </div>
  )
}
